
import React from "react";
import "./CertificationPage.css";

const CertificationPage = () => {
  return (
    <div className="certification-container">
      <h1 className="page-title">Nos Certifications</h1>

      {/* ISO 9001 Section */}
      <section className="certification-section">
        <div className="certification-content">
          <h2>Certification ISO 9001</h2>
          <p>
            <strong>Certification ISO 9001 : L’Excellence Qualité au Cœur de Notre Engagement</strong>
          </p>
          <p>
            Chez JCP Industries, la qualité est bien plus qu’une exigence, c’est une véritable culture d’entreprise.
            Nous sommes fiers d’être certifiés ISO 9001, une reconnaissance internationale qui atteste de notre engagement
            permanent en matière de gestion de la qualité, d’amélioration continue et de satisfaction client.
          </p>
          <h3>Qu’est-ce que la certification ISO 9001 ?</h3>
          <p>
            La norme ISO 9001 est une référence mondiale en matière de management de la qualité.
            Elle garantit que nos processus de production, de contrôle et de gestion répondent aux exigences
            les plus strictes en matière de performance, traçabilité et fiabilité.
          </p>
          <ul>
            <li>Une rigueur absolue dans nos processus</li>
            <li> Un engagement fort pour l’amélioration continue</li>
            <li> Une traçabilité totale de nos produits et services</li>
            <li> Une satisfaction client au cœur de nos priorités</li>
          </ul>
          <p>
            Avec JCP Industries, l’excellence est une promesse tenue.
          </p>

        </div>
        <div className="certification-image">
          <img src="/images/certif/iso 9001.webp" alt="ISO 9001 Certification" />
        </div>
      </section>

      {/* UL Certification Section */}
      <section className="certification-section reverse">
        <div className="certification-content">
          <h2>Certification UL</h2>
          <p>
            <strong>Certification UL : Un Gage de Sécurité et de Fiabilité</strong>
          </p>
          <p>
            Chez JCP Industries, nous plaçons la sécurité et la qualité de nos produits au cœur de nos priorités.
            C’est pourquoi nous sommes fiers d’être certifiés UL (Underwriters Laboratories), une référence mondiale en matière
            de conformité et de sécurité des composants électriques et électroniques.
          </p>
          <h3>Qu’est-ce que la certification RU ?</h3>
          <p>
            La certification UL garantit que nos produits et composants respectent les normes de sécurité les plus strictes
            en matière d’électricité, d’électronique et de performance industrielle.
          </p>
          <ul>
            <li>Sécurité éprouvée</li>
            <li>Fiabilité garantie</li>
            <li>Reconnaissance internationale</li>
          </ul>

        </div>
        <div className="certification-image">
          <img src="/images/certif/ru.PNG.jpg" alt="UL Certification" />
        </div>
      </section>

      <button className="cta-button">
        <a href="/contact" className="cta-link">📞 Contactez-nous dès aujourd’hui et concrétisons ensemble votre vision technologique !</a>
      </button>
    </div>
  );
};

export default CertificationPage;
