import React from "react";
import "./background.css";

// import Header from "./Header";

const HeroVideo = () => {
  return (
     <div className="hero-container">
      <video autoPlay loop muted className="video-background">
        <source src="/assets/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>
  );
};

export default HeroVideo;
