import React from "react";
import "./JobPost.css";

const JobPost = () => {
  return (
    <section className="job-post">
      <a href="/join" className="back-link">← Retour aux offres</a>
      <h1 className="job-title">Concepteur Electronique</h1>

      <div className="job-info">
        <span>📍 Neuilly Plaisance</span>
        <span>📅 30/02/2025</span>
        <span>📄 CDI</span>
        <span>⏳ Partiel</span>
        <span>💰 38 à 45K€ Brut</span>
        <span>👤 5 ans</span>
      </div>
 
 
      <div className="job-content">
        <div className="job-description">
          <h2>Ce que nous allons partager…</h2>
          <p>
            Rejoins une équipe dynamique et impliquée où ton talent viendra compléter les compétences de chacun. En tant que passionné(e) par les systèmes électroniques et embarqués, tu contribueras activement au développement de la société. Ton rôle sera crucial dans toutes les phases des projets de nos clients.
          </p>

          <h3>Immergé(e) dans notre équipe au top, tes missions principales seront de…</h3>
          <ul>
            <li>Réaliser des étapes de recherches (mesures, analyses)</li>
            <li>Étudier ou réaliser un <strong>cahier des charges</strong></li>
            <li>Estimer les charges associées au projet</li>
            <li>Définir l’architecture du projet (<strong>choisir des composants</strong>, optimiser des circuits, saisie de schéma et routage)</li>
            <li>Développer la partie <strong>électronique embarquée</strong></li>
            <li>Analyse et optimisation des circuits analogique et numérique.</li>
            <li><strong>Test et validation de cartes électroniques</strong> (fonctionnel, performance, préparation CEM, Radio…etc)</li>
            <li>Rédaction de documentation interne et client (dossier de fabrication)</li>
            <li>Assurer le lancement en <strong>fabrication de PCB</strong> en amont de la production</li>
            <li>Apporter une expertise aux équipes de production</li>
          </ul>

          <h3>Ton environnement technique se composera de…</h3>
          <ul>
            <li>Un PC portable dernier cri</li>
            <li>Logiciels : e-Cadstar souhaité, Altium est un plus, 3D maîtrisée</li>
            <li>Outils de Mesures : Oscilloscope, Multimètre</li>
            <li>Technologies : 2.4G/5G, LoRa, WiFi, BLE, BT, Ethernet…</li>
            <li>Design Signal & Mixte Analogique et Numérique</li>
          </ul>

          <h3>Nous sommes faits pour travailler ensemble si…</h3>
          <ul>
            <li>Tu es diplômé(e) d’une école d’ingénieur BAC+5 ou équivalent et tu justifies d’une première expérience dans ce domaine</li>
            <li>Ta curiosité, ta ténacité et ta volonté de progresser te permettent d’être force de proposition afin de participer à la réussite de nos projets</li>
            <li>Tu es passionné par les nouvelles technologies</li>
            <li>Tu es apte à partager ta bonne humeur !</li>
          </ul>

          <h3>Quoi ? Toujours pas convaincu ?</h3>
          <p>
            Alors voici les avantages que nous te proposons : 80% de la mutuelle et 100% de la prévoyance prise en charge, des horaires flexibles et du télétravail régulier. Un partage des résultats de l’entreprise.
          </p>
          <p>
            Rejoins-nous dès aujourd’hui et fais partie de l’aventure JCP Industries !
          </p>
          <p>
            Merci d'envoyer ton CV et une lettre de motivation  à <a href="mailto:jcp-contact@orange.fr">jcp-contact@orange.fr</a>
          </p>
        </div>

 
      </div>

      <footer className="footer">
        <p>Powered by <strong>JobPass</strong></p>
      </footer>
    </section>
  );
};

export default JobPost;
