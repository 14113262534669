import React from "react";
import "./CompanyIntro.css";

const CompanyIntro = () => {
  return (
    <section className="company-about">
      <div className="content-container">
 
        <p>
          <h2><strong>Un savoir-faire complet pour une intégration optimale</strong></h2>

          <p>
            Grâce à notre expertise et à nos moyens industriels performants, nous prenons en charge l’ensemble du cycle de vie de vos produits :
          </p>

          <p>
             <strong>Étude et conception</strong> : Définition de vos besoins, rédaction du cahier des charges, schématisation et routage des cartes électroniques.
            <br /><br />
             <strong>Fabrication et intégration</strong> : Câblage de composants (CMS, BGA…), assemblage de faisceaux et de sous-ensembles, intégration dans des systèmes globaux.
            <br /><br />
            <strong>Tests et validation</strong> : Programmation, tests fonctionnels et contrôle qualité pour garantir des performances optimales.
          </p>

        </p>
      </div>
    </section>
  );
};

export default CompanyIntro;
