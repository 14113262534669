import React from "react";
import "./Certifications.css";

const Certifications = () => {
  return (
    <section className="certifications">
      <div className="certifications-container">
        {/* Left Side - Text */}
        <div className="certifications-text">
          <h2>Nos Certifications : Un Engagement Qualité & Environnement</h2>
          <p>
            Chez JCP Industries, la qualité et le respect de l’environnement sont au cœur de notre démarche industrielle.
            Nos processus et nos équipements de production sont rigoureusement contrôlés afin de garantir une gestion optimisée des risques,
            une performance constante et le respect des délais de livraison.
          </p>
          <p>
            <strong>Notre double certification <span className="blue-text">ISO 9001</span> et <span className="blue-text">UL </span></strong>
            atteste de notre engagement envers l’excellence opérationnelle et le développement durable.
            Nous intégrons l’innovation et la Responsabilité Sociétale des Entreprises (RSE) dans chacune de nos actions,
            afin de contribuer activement à la construction <strong>d’une industrie plus performante et durable.</strong>
          </p>

        </div>

        {/* Right Side - Certification Logos */}
        <div className="certifications-logos">
          <img src="/images/certif/iso 9001.webp" alt="ISO 9001 Certification" className="certification-logo" />
          <img src="/images/certif/ru.PNG.jpg" alt="RU Certification" className="certification-logo" />
        </div>
      </div>
    </section>
  );
};

export default Certifications;
