import React from "react";
import "./CompanyOverview.css";

const CompanyOverview = (props) => {
  const overviewData = props.data;
  return (
    <section className="company-overview">
      {/* Title */}
      <h2 className="section-title">
        <span className="highlight">JCP Industries : L’intégrateur électronique au service de votre performance</span>
      </h2>
      {/* <h1 className="main-title">
        {overviewData.main_title}{" "}
        <span className="highlight"></span>
      </h1> */}

      {/* Subtitle */}
      <p className="subtitle">
        Depuis plus de 30 ans, JCP Industries s’impose comme un intégrateur de solutions électroniques et électrotechniques sur mesure. De l’étude à la fabrication, nous intégrons des technologies de pointe pour transformer vos besoins en solutions fiables et performantes.

        <span className="blue-text">

        </span>
      </p>

      <div className="stats-container">
        <div className="stat-box stat-1">
          <img src={overviewData.images.expertise} alt="Microchip Icon" className="stat-icon" />
          <span>{overviewData.stats.expertise}</span>
        </div>
        <div className="stat-box stat-2">
          <img src={overviewData.images.factory} alt="Industry Icon" className="stat-icon" />
          <span>{overviewData.stats.factory}</span>
        </div>
        <div className="stat-box stat-3">
          <img src={overviewData.images.certified} alt="Certificate Icon" className="stat-icon" />
          <span>{overviewData.stats.certified}</span>
        </div>
        <div className="stat-box stat-4">
          <img src={overviewData.images.medal} alt="Battery Icon" className="stat-icon" />
          <span>{overviewData.stats.medal}</span>
        </div>
      </div>


      {/* Company Description */}
      {/* Company Description */}
      <p className="company-description">
        <span className="blue-text">Une intégration complète :</span> Nous prenons en charge l’ensemble du cycle de développement, de la conception des cartes électroniques au montage des sous-ensembles, en passant par le câblage de faisceaux et l’assemblage final.
        <br /><br />
        <span className="blue-text">Un intégrateur agile et réactif :</span> Notre structure flexible nous permet d’adapter nos prestations à vos exigences, avec une réactivité et un respect des délais inégalés.
        <br /><br />
        <span className="blue-text">Une expertise reconnue :</span> Grâce à notre savoir-faire industriel, nous intégrons vos produits électroniques et électrotechniques avec précision et rigueur, du prototype à la production en série.
        <br /><br />
        <span className="blue-text">Un partenaire d’intégration fiable :</span> Proximité, qualité et engagement total sont les valeurs qui font de JCP Industries un acteur de confiance depuis 1992.
        <br /><br />
        Faites le choix d’un intégrateur expert pour donner vie à vos projets électroniques avec maîtrise et excellence.
      </p>

      {/* Call to Action Button */}
      <button className="cta-button">
        <a href="/contact" className="cta-link">📞 Contactez-nous dès aujourd’hui et concrétisons ensemble votre vision technologique !</a>
      </button>
    </section>
  );
};

export default CompanyOverview;
