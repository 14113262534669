import React, { useState, useEffect } from "react";
import GraySection from "../components/Blocks/GrayBlock/GrayBlock";
import ProductionCapabilities from "./Quality/ProductionCapabilities";
import Timeline from "./Quality/Timeline";
import domainJson from "../data/domain.json";

const Quality = () => {
  const [domainData, setDomainData] = useState({});

    useEffect(() => {
        setDomainData(domainJson);
      }, []);

    return (
        <>
            {domainData.quality && <GraySection props={domainData.quality} />}
            <Timeline />
            {/* <ProductionCapabilities /> */}
        </>
    );
};
export default Quality;