import React from "react";
import Slider from "react-slick";

// Import Slick's default styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./PartnersSlider.css"; // Custom styles

// Custom Previous Arrow
const PrevArrow = ({ onClick }) => (
  <button className="slick-arrow slick-prev" onClick={onClick}>
    &#8249; {/* Unicode for left arrow (‹) */}
  </button>
);

// Custom Next Arrow
const NextArrow = ({ onClick }) => (
  <button className="slick-arrow slick-next" onClick={onClick}>
    &#8250; {/* Unicode for right arrow (›) */}
  </button>
);

function PartnersSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 5, // Display 4 logos per slide
    slidesToScroll: 3,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay every 2 seconds
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const partners = [
    { img: "/images/portfolio/APAVE.jpg", name: "apaver" },
    { img: "/images/portfolio/CEA.png", name: "cea" },
    { img: "/images/portfolio/CLEMESSY.jpg", name: "clemssy" },
    { img: "/images/portfolio/CNRS.jpg", name: "cnrs" },
    { img: "/images/portfolio/COYOTE.png", name: "coyotte" },
    { img: "/images/portfolio/EDF.jpg", name: "EDF" },
    { img: "/images/portfolio/RATP.jpg", name: "RATP" },

    { img: "/images/portfolio/SCH.png", name: "sch" },
    { img: "/images/portfolio/SNCF.png", name: "scnf" },

    { img: "/images/portfolio/STENTORIUS.jpg", name: "STENTORIUS" },
    { img: "/images/portfolio/SVD.png", name: "svd" },
    { img: "/images/portfolio/orange.png", name: "orange" },
  ];

  return (
    <section className="partners-section">
      <h2 className="partners-title">Nos clients</h2>

      <Slider {...settings}>
        {partners.map((partner, index) => (
          <div key={index} className="partner-slide">
            <img src={partner.img} alt={partner.name} className="partner-logo" />
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default PartnersSlider;
