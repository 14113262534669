import React, { useState, useEffect } from "react";
import GraySection from "../components/Blocks/GrayBlock/GrayBlock";

import Timeline from "./Electronimecanic/Timeline";
import domainJson from "../data/domain.json";

const Electronimecanic = () => {
    const [domainData, setDomainData] = useState({});

    useEffect(() => {

        setDomainData(domainJson);
    }, []);

    return (
        <>
            {domainData.electronimecanic && <GraySection props={domainData.electronimecanic} />}
            <Timeline />
        </>
    );
};
export default Electronimecanic;