import React, { useState, useEffect } from "react";
import GraySection from "../components/Blocks/GrayBlock/GrayBlock";
 
import Timeline from "./Electronics/Timeline";
import domainJson from "../data/domain.json";

const Electronic = () => {
    const [domainData, setDomainData] = useState({});

    useEffect(() => {
        
        setDomainData(domainJson);
    }, []);
 
    return (
        <>
            {domainData.electronic && <GraySection props={domainData.electronic} />}
            <Timeline />
            {/* {domainData.equipement && <Equipment props={domainData.equipement}/> } */}

        </>
    );
};
export default Electronic;