import React from "react";
// import "./Timeline.css"; // Import CSS file

const Timeline = () => {
  const timelineData = [
    {
      icon: "/images/domaines/electro1.png",
      title: "Fabrication et câblage de faisceaux et filerie",
      description: (
        <>
          ✔️ <strong>Conception et fabrication</strong> de faisceaux sur mesure pour assurer une interconnexion fiable et durable.
          <br /><br />
          ✔️ <strong>Assemblage de cordons électriques</strong> adaptés aux environnements industriels exigeants.
          <br /><br />
          ✔️ <strong>Gestion de la connectique et de la protection</strong> pour garantir sécurité et longévité des installations.
        </>
      ),
    },
    {
      icon: "/images/domaines/electro2.png",
      title: "Câblage et intégration de systèmes électrotechniques",
      description: (
        <>
          ✔️ <strong>Câblage de baies, armoires et coffrets électriques</strong> : Intégration de composants de commande et de puissance pour une gestion optimisée de l’énergie.
          <br /><br />
          ✔️ <strong>Montage et intégration de sous-ensembles</strong> : Fabrication et assemblage de modules mécaniques, électriques et électroniques.
          <br /><br />
          ✔️ <strong>Prise en charge des pièces usinées et tôleries</strong> : Gestion complète de l’industrialisation en partenariat avec des fournisseurs spécialisés.
        </>
      ),
    },
    {
      icon: "/images/domaines/electro3.png",
      title: "Pourquoi choisir JCP Industries ?",
      description: (
        <>
          ✔️  <strong>Un savoir-faire reconnu</strong> depuis plus de 30 ans dans l’intégration de systèmes électroniques et électrotechniques.
          <br /><br />
          ✔️   <strong>Une approche globale</strong> : De l’étude à l’assemblage, nous gérons toutes les étapes de fabrication.
          <br /><br />
          ✔️  <strong>Un service flexible et réactif</strong>, capable de produire du prototype à la série.
          <br /><br />
          ✔️  <strong>Une exigence qualité élevée</strong>, avec des processus de fabrication rigoureux et un contrôle strict des performances.
          <br /><br />
          ✔️  <strong>Une relation de proximité</strong> avec nos clients, axée sur l’écoute et la satisfaction.
        </>
      ),
    },


  ];


  return (
    <section className="timeline-section">
      <div className="timeline-container">
        {timelineData.map((item, index) => (
          <div className="timeline-item" key={index}>
            {/* Vertical Line */}
            <div className="timeline-line"></div>

            {/* Circle Icon */}
            <div className="timeline-icon">
              <img src={item.icon} alt="Timeline Icon" />
            </div>

            {/* Text Content */}
            <div className="timeline-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        <button className="cta-button">
          <a href="/contact" className="cta-link">📞 Contactez-nous dès aujourd’hui et concrétisons ensemble votre vision technologique !</a>
        </button>
      </div>
    </section>
  );
};

export default Timeline;