import React, { useState, useEffect } from "react";
import PageTop from "../components/Blocks/PageTop/PageTop";
import ContactForm from "./Contact/ContactForm";
import domainJson from "../data/blog.json";

const Electronic = () => {
    const [domainData, setDomainData] = useState({});

    useEffect(() => {
        setDomainData(domainJson);
    }, []);

    
    return (
        <>
        {domainData && domainData.contact && <PageTop props={domainData.contact}/>}
        <ContactForm />
        </>
    );
};
export default Electronic;