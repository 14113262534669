import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./ContactForm.css";

const ContactForm = () => {
  // State for form fields
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  // State for notification
  const [notification, setNotification] = useState({ type: "", message: "", show: false });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Show notification and auto-dismiss after 3 seconds
  const showNotification = (type, message) => {
    setNotification({ type, message, show: true });

    setTimeout(() => {
      setNotification({ type: "", message: "", show: false });
    }, 3000);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailData = {
      to: "reda.neqrouz@outlook.com",
      subject: `Message de ${formData.firstName} ${formData.lastName}`,
      body: `Nom: ${formData.lastName}\nPrénom: ${formData.firstName}\nTéléphone: ${formData.phone}\nEmail: ${formData.email}\n\nMessage:\n${formData.message}`,
    };

    try {
      const response = await fetch("https://mail.mti7.net/send-email", {
        method: "POST",
        mode: "cors", // Enable cross-origin requests
        headers: {
          "Authorization": "9694b3708530743c8d14a1143d779264",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        showNotification("success", "E-mail envoyé avec succès !");
        setFormData({ firstName: "", lastName: "", phone: "", email: "", message: "" });
      } else {
        throw new Error("Échec de l'envoi de l'e-mail.");
      }
    } catch (error) {
      showNotification("error", "Une erreur est survenue, veuillez réessayer.");
    }
  };

  return (
    <section className="contact-form-container">
      {/* Notification Popup */}
      {notification.show && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}

      {/* Left Side: Form & Text */}
      <div className="contact-form-content">
        <h2><span className="highlight">Contactez-nous</span></h2>

        {/* Form Fields */}
        <form className="contact-form" onSubmit={handleSubmit}>
          <input type="text" name="firstName" placeholder="Prénom" value={formData.firstName} onChange={handleChange} required />
          <input type="text" name="lastName" placeholder="Nom" value={formData.lastName} onChange={handleChange} required />
          <input type="tel" name="phone" placeholder="Téléphone" value={formData.phone} onChange={handleChange} required />
          <input type="email" name="email" placeholder="E-Mail" value={formData.email} onChange={handleChange} required />
          <textarea name="message" placeholder="Rédigez ici" value={formData.message} onChange={handleChange} required></textarea>
          <button type="submit">Envoyer</button>
        </form>

        {/* Disclaimer */}
        <p className="disclaimer">
          *En soumettant ce formulaire, j’accepte que les informations saisies
          soient exploitées par JCP Industries dans le cadre de nos campagnes de
          communication et de la relation commerciale qui peut en découler.
        </p>
      </div>

      {/* Right Side: Location & Image */}
      <div className="contact-location">
        <h3>Comment venir :</h3>
        <p>Nos locaux sont situés dans la région parisienne.</p>
        <p>
          <span className="geolocation-icon">
            <a href="https://www.google.com/maps/place/48%C2%B051'53.7%22N+2%C2%B031'03.0%22E/" target="_blank" rel="noopener noreferrer">
              <i className="fas fa-map-marker-alt"></i>
            </a>
          </span>
          ZI Les Renouillères, <br /> 5, Allée Roland Garros, <br /> 93360 Neuilly Plaisance
        </p>

        {/* Team Image */}
        <div className="contact-image">
          <img src="/images/intro-bg.jpg" alt="Équipe JCP Industries" />
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
