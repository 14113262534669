import React from "react";
import "./JobOffers.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import FontAwesome CSS

const JobOffers = () => {
  return (
    <section className="job-offers">
      <div className="job-header">
        <h2>Offres d'emploi</h2>
 
      </div>

      <div className="job-container">
        <div className="job-card">
          <h3>Concepteur Electronique</h3>
          <div className="job-info">
            <p>
              <i className="fas fa-map-marker-alt"></i> <strong>Neuilly Plaisance</strong>
            </p>
            <p>
              <i className="fas fa-briefcase"></i> <strong>CDI</strong>
            </p>
            <p>
              <i className="fas fa-calendar-alt"></i> <strong>3/02/2025</strong>
            </p>
          </div>
          <button className="view-more">
            <i className="fas fa-arrow-right"></i> <a href="/jobpost" > Voir plus </a>
          </button>
        </div>
      </div>

 
    </section>
  );
};

export default JobOffers;
