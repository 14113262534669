import React, { useState, useEffect } from "react";
import "./SimilarArticles.css";
import JsonblogData from "../../data/blog.json";
 
const SimilarArticles = () => {
   const [blogData, setBlogData] = useState({});

  useEffect(() => {
     setBlogData(JsonblogData);
  }, []);

  return (
    <section className="similar-articles">
      <h2>Articles Similaires:</h2>
      {blogData.posts && 
      <div className="articles-container">
        {blogData.posts.map((article) => (
          <a href={article.link} > 
          <div key={article.id} className="article-card">
            <img src={article.background} alt={article.title} />
            <p>{article.title}</p>
          </div>
          </a>
        ))}
      </div>
    }
    </section>
  );
};

export default SimilarArticles;
