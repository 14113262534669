import React from "react";
import "./ServiceBlock.css";

const services = [
  {
    icon: "/images/domaines/quality1.webp",
    title: "Qualité, Fiabilité & Rapidité",
    description: "Nous vous accompagnons dans votre projet industriel. De prototype à l’industrialisation de la carte.",
    href :"/domaines/qualite"
  },
  {
    icon: "/images/domaines/electo-bg.png",
    title: "Prestations électroniques",
    description: "Les prestations proposées sont de nature à couvrir l'intégralité des besoins du métier.",
    href : "/domaines/prestations-electroniques"
  },
  {
    icon: "/images/domaines/image.png",
    title: "Prestations Électrotechniques",
    description: "Chez JCP Industries, nous mettons notre savoir-faire au service de vos besoins en électrotechnique, en proposant des solutions adaptées à chaque projet.",
    href: "/domaines/prestations-electrotechniques"
  }
];

const ServicesSection = () => {
  return (
    <section className="services" id="services">
      {/* <h2>Conception de carte électronique : Nos services</h2> */}
      <p>
       Chez  <span className="highlight"> JCP Industries </span> , nous mettons à profit la souplesse et la réactivité d’une structure
       à taille humaine pour vous offrir une <span className="highlight"> solution globale et sur mesure</span> , adaptée à vos besoins. 
       De l’étude à la fabrication, nous vous accompagnons à chaque étape pour donner vie à vos projets électroniques avec <span className="highlight"> expertise et précision</span>.
      </p>

      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="icon-circle">
              <img src={service.icon} alt={service.title} className="service-icon" />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <a href={service.href}>En savoir plus…</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;