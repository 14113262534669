import React, { useState, useEffect } from "react";
import JobOffers from "./JobOffers/JobOffers";

import JsonData from "../data/data.json";

const Join = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);

    }, []);


    return (
        <div>
            <JobOffers />
        </div>
    );
};

export default Join;