import React, { useState, useEffect } from "react";
import PageTop from "../../components/Blocks/PageTop/PageTop";
import Embarquee from "../Content/Embarquee";
import Fiabilite from  "../Content/Fiabilite";
import SimilarArticles from "./SimilarArticles";
import Electrotechnique from "../Content/Electrotechnique";

const BlogPage = (props) => {
  console.log("data:data ", props)
  const data = props.data;


  const renderContent = () => {
 
    switch (data.postType) {
      case "embarquee":
        return <Embarquee />;
      case "fiabilite":
        return <Fiabilite />;
      default:
        return <Electrotechnique />;
    }
 
  };
 

  return (
    <>
      <PageTop props={data}/>
      {renderContent()}
      <SimilarArticles />
    </>
  );
};

export default BlogPage;