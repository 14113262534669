import React from "react";
import "./PageTop.css";

const PageTop = (props) => {
 
  const { background, title } = props.props;

  return (
    <section className="contact-section">
      {/* Background Image */}
      <div
        className="contact-image"
        style={{ backgroundImage: `url("${background}")` }}
      ></div>

      {/* Contact Title */}
      <div className="contact-title">
        <h2>{title}</h2>
      </div>
    </section>
  );
};

export default PageTop;