import React from "react";
import "./ImageList.css";

const ImageList = (props) => {
 
  return (
    <div className="image-list-container">
 
      <div className="image-grid">
        {props.data ? props.data.map((image) => (
          <div key={image.id} className="image-item">
            <img src={image.src} alt={image.alt} className="image" />
            <div className="overlay">
              {image.text && <div className="overlay-text">{image.text}</div> } 
            </div>
          </div>
        )) : "No data"}
      </div>
    </div>
  );
};

export default ImageList;