import React from "react";
import "./BlogSection.css";


const BlogSection = (props) => {
 
    const blog = props.blog;
    return (
        <section className="blog-section">
            {/* Image Row */}
            <div className="blog-images">
                {blog.images.map((image, index) => (
                    <div key={index} className="blog-image-circle">
                        <img src={image} alt={`Blog ${index + 1}`} />
                    </div>
                ))}
            </div>

            {/* Blog Content */}
            <div className="blog-content">
                <div className="blog-content-text">
                    <h2>Blog</h2>
                    <p>
                        Retrouvez ici toute l’actualité de la filière industries électronique.
                        Le marché des fabricants de cartes et systèmes électroniques est en
                        évolution constante.
                    </p>
                    <p>
                        Entre innovations technologiques et relocalisations, les sujets ne
                        manquent pas. Retrouvez ici des articles de fond et d’actualités.
                    </p>
                </div>
                <a href="/contact" className="blog-button">
                    Contactez-nous
                </a>
            </div>
        </section>
    );
};

export default BlogSection;
