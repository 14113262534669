import React from "react";
import "./Timeline.css"; // Import CSS file

const Timeline = () => {
  const timelineData = [

    {
      icon: "/images/domaines/quality-2.png",
      title: "Étude et conception de systèmes électroniques",
      description: (
        <>
          ✔️ <strong>Définition et cadrage du projet</strong> : Analyse de vos besoins, identification des contraintes et validation des objectifs.
          <br /><br />
          ✔️ <strong>Rédaction du cahier des charges</strong> : Structuration des spécifications techniques pour garantir la faisabilité et l’optimisation des coûts.
          <br /><br />
          ✔️ <strong>Conception de cartes électroniques</strong> : Développement de circuits imprimés sur mesure en fonction des exigences fonctionnelles et environnementales.
          <br /><br />
          ✔️ <strong>Étude schématique & routage</strong> : Conception précise des circuits pour une performance optimale et une fiabilité accrue.
        </>
      ),
    },
    {
      icon: "/images/domaines/composant.png",
      title: "Fabrication et câblage électronique",
      description: (
        <>
          ✔️ <strong>Élaboration du dossier technique</strong> : Documentation détaillée pour une fabrication maîtrisée.
          <br /><br />
          ✔️ <strong>Approvisionnement et gestion des composants</strong> : Sélection des meilleurs composants pour garantir pérennité et qualité.
          <br /><br />
          ✔️ <strong>Câblage de cartes électroniques</strong> : Intégration de composants traversants ou CMS, y compris BGA et autres technologies avancées.
          <br /><br />
          ✔️ <strong>Programmation des composants</strong> : Mise en place de logiciels embarqués et paramétrage de circuits programmables.
        </>
      ),
    },
    {
      icon: "/images/domaines/quality1.webp",
      title: "Tests et validation",
      description: (
        <>
          ✔️ <strong>Contrôle qualité rigoureux</strong> : Vérification des soudures, contrôle optique et validation des performances électriques.
          <br /><br />
          ✔️ <strong>Tests fonctionnels</strong> : Simulation et validation en conditions réelles avant mise en production.
          <br /><br />
          ✔️ <strong>Intégration dans des systèmes globaux</strong> : Assemblage des cartes et composants pour assurer une compatibilité parfaite avec vos produits.
        </>
      ),
    },
  ];



  return (
    <section className="timeline-section">
      <div className="timeline-container">
        {timelineData.map((item, index) => (
          <div className="timeline-item" key={index}>
            {/* Vertical Line */}
            <div className="timeline-line"></div>

            {/* Circle Icon */}
            <div className="timeline-icon">
              <img src={item.icon} alt="Timeline Icon" />
            </div>

            {/* Text Content */}
            <div className="timeline-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        <button className="cta-button">
          <a href="/contact" className="cta-link">📞 Contactez-nous dès aujourd’hui et concrétisons ensemble votre vision technologique !</a>
        </button>
      </div>
    </section>
  );
};

export default Timeline;