import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Section/Footer/Footer";
import Header from "./components/Section/Header/Header";
import Quality from "./Pages/Quality";
import Electronic from "./Pages/Electronic";
import Electronimecanic from "./Pages/Electronimecanic";
import Landing from "./Pages/Landing";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import Join from "./Pages/Join";
import BlogPage from "./Pages/Blog/BlogPage";
import CertificationPage from "./Pages/CertificationPage";
import About from "./Pages/About";

import JsonData from "./data/data.json";
import JsonblogData from "./data/blog.json";
import JobPost from "./Pages/JobOffers/JobPost";
import "./App.css";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [blogData, setBlogData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
    setBlogData(JsonblogData);
  }, []);


  return (
    <Router>
      <Header data={landingPageData.Header} />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/domaines/qualite" element={<Quality />} />
        <Route path="/domaines/prestations-electroniques" element={<Electronic />} />
        <Route path="/domaines/prestations-electrotechniques" element={<Electronimecanic />} />
        <Route path="/about" element={<About />} />
        <Route path="/certifications" element={<CertificationPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/join" element={<Join />} />

        {blogData && blogData.posts &&
          <Route path="/blog/electronique-embarquee" element={<BlogPage data={blogData.posts[0]} />} />}
        {blogData && blogData.posts &&
          <Route path="/blog/garantir-fiabilite" element={<BlogPage data={blogData.posts[1]} />} />}
        {blogData && blogData.posts &&
          <Route path="/blog/electrotechnique-reussie" element={<BlogPage data={blogData.posts[2]} />} />}
        <Route path="/jobpost" element={<JobPost />}/>
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;