import React from "react";
// import "./Timeline.scss"; // Import CSS file

const Timeline = () => {
  const timelineData = [
    {
      icon: "/images/domaines/engagement.webp",
      title: "Notre Engagement Qualité",
      description: (
        <>
            Depuis sa création, <strong>JCP Industries</strong> s’engage à offrir des solutions électroniques de haute qualité en s’appuyant sur des processus rigoureux et des normes reconnues. La satisfaction client et l’amélioration continue sont au cœur de notre démarche.
            <br />« Maintenir un niveau d’excellence tout en restant une entreprise agile et à taille humaine est notre priorité. Nous souhaitons accompagner durablement nos partenaires en garantissant innovation et fiabilité. » <strong>JCP Industries</strong>

         </>
      ),
    },
    {
      icon: "/images/domaines/jcp-industries.webp",
      title: "Notre Responsabilité Sociétale",
      description: (
        <>
            Chez <strong>JCP Industries</strong>, nous avons conscience de notre rôle dans un monde en pleine mutation. Nous nous engageons activement à favoriser le développement durable et la transmission des savoirs.
            <br />✔️  Formation continue de nos collaborateurs pour assurer leur montée en compétences et leur employabilité. 
            <br />✔️   Participation à l’orientation des jeunes en valorisant les métiers de l’électronique et en développant l’apprentissage en alternance. 
            <br />✔️  Réduction de notre empreinte environnementale grâce à une optimisation de nos procédés de fabrication.
   
         </>
      ),
    },
 
    {
      icon: "/images/domaines/jcp-industries1.webp",
      title: "Innovation et Transformation Numérique",
      description: (
        <>
            Nous sommes convaincus que l’innovation est la clé de la transformation industrielle. <strong>JCP Industries</strong> participe activement aux évolutions technologiques en intégrant des solutions intelligentes et adaptées aux défis de demain.
            <br /> Notre ambition est d’accompagner nos clients vers des solutions plus performantes et adaptées aux nouveaux enjeux du numérique et de l’industrie 4.0.
      
        </>
      ),
    },

    {
      icon: "/images/domaines/francaise.webp",
      title: "Fabrication Française",
      description: (
        <>
             Nos infrastructures de production et notre bureau d’études sont situés en France, garantissant une <strong>fabrication 100% française</strong> et un contrôle qualité rigoureux à chaque étape de la production.
      
        </>
      ),
    },
  ];

  return (
    <section className="timeline-section">
      <div className="timeline-container">
        {timelineData.map((item, index) => (
          <div className="timeline-item" key={index}>
            {/* Vertical Line */}
            <div className="timeline-line"></div>

            {/* Circle Icon */}
            <div className="timeline-icon">
              <img src={item.icon} alt="Timeline Icon" />
            </div>

            {/* Text Content */}
            <div className="timeline-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        <button className="cta-button">
          <a href="/contact" className="cta-link">📞 Contactez-nous dès aujourd’hui et concrétisons ensemble votre vision technologique !</a>
        </button>
      </div>
    </section>
  );
};

export default Timeline;