import React from "react";
import "./ValuesAndCommitments.css";

const ValuesAndCommitments = () => {
  return (
    <section className="values-section">
      {/* Title */}
      <h2 className="section-title">Nos Valeurs</h2>

      {/* Values Section */}

      <div className="values-container">
        <div className="value-item light-blue">
          <img src="/images/domaines/innovation.webp" alt="Innovation" className="value-icon" />
        </div>
        <div className="value-item dark-blue">
          <img src="/images/domaines/proximite.webp" alt="Proximité" className="value-icon" />
        </div>
        <div className="value-item light-blue">
          <img src="/images/domaines/qualite.webp" alt="Qualité" className="value-icon" />
        </div>
      </div>
    </section>
  );
};

export default ValuesAndCommitments;
