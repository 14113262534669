import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    domaines: false,
    about: false,
  });

  const menuRef = useRef(null); // Reference for the menu

  // Toggle the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Toggle dropdown menus
  const toggleDropdown = (menu) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setDropdownOpen({ domaines: false, about: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close menu when clicking on a menu item
  const handleMenuItemClick = () => {
    setMenuOpen(false);
    setDropdownOpen({ domaines: false, about: false });
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="/img/logo JCP.png" alt="JCP Logo" />
      </div>

      <button className={`hamburger ${menuOpen ? "active" : ""}`} onClick={toggleMenu} style={{ fontSize: "50px" }}>
        ☰
      </button>

      <nav ref={menuRef} className="nav">
        <ul className={menuOpen ? "active" : ""}>
          <li><NavLink exact to="/" activeClassName="active" onClick={handleMenuItemClick}>Accueil</NavLink></li>

          {/* Nos Domaines Dropdown */}
          <li className="dropdown">
            <span onClick={() => toggleDropdown("domaines")}>
              Nos domaines <i className="fas fa-caret-down"></i>
            </span>
            <ul className={`dropdown-content ${dropdownOpen.domaines ? "show" : ""}`}>
              <li><NavLink to="/domaines/qualite" activeClassName="active" onClick={handleMenuItemClick}>Qualité</NavLink></li>
              <li><NavLink to="/domaines/prestations-electroniques" activeClassName="active" onClick={handleMenuItemClick}>Prestations électroniques</NavLink></li>
              <li><NavLink to="/domaines/prestations-electrotechniques" activeClassName="active" onClick={handleMenuItemClick}>Prestations électrotechniques</NavLink></li>
            </ul>
          </li>

          {/* About Dropdown */}
          <li className="dropdown">
            <span onClick={() => toggleDropdown("about")}>
              A propos <i className="fas fa-caret-down"></i>
            </span>
            <ul className={`dropdown-content ${dropdownOpen.about ? "show" : ""}`}>
              <li><NavLink to="/about" activeClassName="active" onClick={handleMenuItemClick}>Qui sommes-nous</NavLink></li>
              <li><NavLink to="/certifications" activeClassName="active" onClick={handleMenuItemClick}>Certifications</NavLink></li>
            </ul>
          </li>

          <li><NavLink to="/blog" activeClassName="active" onClick={handleMenuItemClick}>Blog</NavLink></li>
          <li><NavLink to="/contact" activeClassName="active" onClick={handleMenuItemClick}>Contact</NavLink></li>
          <li><NavLink to="/join" activeClassName="active" onClick={handleMenuItemClick}>On recrute</NavLink></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;