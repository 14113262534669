import React, { useState, useEffect } from "react";
import HeroVideo from "../components/Video/Background";
import CompanyOverview from "../components/Blocks/CompanyOverview";
import ImageList from "../components/ImageList/ImageList";
import ServiceBlock from "../components/Blocks/ServiceBlock";
import NewsSection from "../components/Blocks/NewsSection";
import TestimonialSlider from "../components/Blocks/TestimonialSlider";
import PartnersSlider from "../components/Blocks/PartnersSlider";
import JsonData from "../data/data.json";
import "./Landing.css"; // Import the CSS file for the popup

const Landing = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setLandingPageData(JsonData);

        // Check if the user has seen the popup
        const hasSeenPopup = localStorage.getItem("hasSeenPopup");
        if (!hasSeenPopup) {
            setShowPopup(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("hasSeenPopup", "true");
        setShowPopup(false);
    };

    const handleReject = () => {
        localStorage.setItem("hasSeenPopup", "true");
        setShowPopup(false);
    };

    return (
        <div>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>Nous respectons votre vie privée</h2>
                        <p>
                            Certaines fonctionnalités de ce site reposent sur l’usage de cookies.
                            Le bandeau cookies ne s’affiche pas sur la page d’accueil lors de votre navigation car seuls des cookies nécessaires au fonctionnement du site sont déposés (vous pouvez cependant vous y opposer en cliquant sur le bouton « Je refuse » ci-dessous).
                        </p>
                        <p>
                            Les services de mesure d'audience sont nécessaires au fonctionnement du site en permettant sa bonne administration. Vous avez cependant la possibilité de vous opposer à leur usage en cliquant sur le bouton « Je refuse » ci-dessous).
                        </p>
                        <div className="popup-buttons">
                            <button onClick={handleAccept}>J'accepte tout</button>
                            <button onClick={handleReject}>Je refuse</button>
                        </div>
                    </div>
                </div>
            )}
            <HeroVideo />
            {landingPageData.overview && <CompanyOverview data={landingPageData.overview} />}
            <ImageList data={landingPageData.ImageList} />
            <ServiceBlock />
            <NewsSection />
            {/* <TestimonialSlider /> */}
            <PartnersSlider />
        </div>
    );
};

export default Landing;