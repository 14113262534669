import React, { useState, useEffect } from "react";
import GraySection from "../components/Blocks/GrayBlock/GrayBlock";
import CompanyIntro from "./About/CompanyIntro"; 
import ValuesAndCommitments from "./About/ValuesAndCommitments";
import Timeline  from "./About/Timeline";
import Certifications from "./About/Certifications";
import domainJson from "../data/domain.json";

const About = () => {
    const [domainData, setDomainData] = useState({});

    useEffect(() => {
        
        setDomainData(domainJson);
    }, []);

 
    return (
        <>
            {domainData.about && <GraySection props={domainData.about} />}
            <CompanyIntro />
            <ValuesAndCommitments />
            <Timeline />
            <Certifications />
        </>
    );
};
export default About;