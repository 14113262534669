import React from "react";
import "./NewsCards.css";

// Import images
 
 

const NewsCards = (props) => {
    const newsData = props.posts;
    console.log("newsData", newsData)
  return (
    <section className="news-section">
      <div className="news-container">
        {newsData.map((news, index) => (
          <div key={index} className="news-card">
            <div className="news-image" style={{ backgroundImage: `url(${news.background})` }}>
              <div className="news-category">{news.category}</div>
            </div>
            <div className="news-content">
              <h3>{news.description}</h3>
              <a href={news.link} className="news-link">
                LIRE LA SUITE »
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewsCards;
