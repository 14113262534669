import React from "react";
import "./Footer.css"; // Import the CSS file
 
function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="footer-container">
        
        {/* Logo & Tagline */}
        <div className="footer-logo">
          <img src="/img/logo JCP.png" alt="JCP industries Logo" />
          <p>Industrie du futur <br /> Durable et innovante</p>
        </div>

        {/* Blog Section */}
        <div className="footer-section">
          <h3>BLOG</h3>
          <ul>
            <li><a href="/blog/electronique-embarquee">Article 1: L’Industrie 4.0 et l’électronique embarquée, comment les  intégrateurs s’adaptent aux nouveaux défis ?!</a></li>
            <li><a href="/blog/garantir-fiabilite">Article 2:Cartes électroniques : comment garantir une qualité et une fiabilité optimales ?</a></li>
            <li><a href="/blog/electrotechnique-reussie">Article 3: Câblage industriel</a></li>
          </ul>
        </div>

        {/* Information Section */}
        <div className="footer-section">
          <h3>INFORMATION</h3>
          <ul>
            <li><a href="#footer">Conditions générales de vente</a></li>
            <li><a href="#footer">Mentions légales</a></li>

          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="footer-section">
          <h3>RÉSEAUX SOCIAUX</h3>
          <div className="footer-socials">
            <a href="#footer"><i className="fab fa-twitter"></i></a>
            <a href="#footer"><i className="fab fa-youtube"></i></a>
            <a href="#footer"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
