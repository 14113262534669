import React, { useState, useEffect } from "react";
import "./NewsSection.css";
import blogJson from "../../data/blog.json";

const newsData = [
  {
    image: "/img/images/a1.jpg",
    category: "ÉLECTRONIQUES EMBARQUÉE",
    title: "ELECTRONIE : Une entreprise encore plus engagée dans la transition écologique avec le Coq Vert !",
    link: "#",
  },
  {
    image: "/img/images/a2.jpeg",
    category: "ACTUALITÉS INDUSTRIES ÉLECTRONIQUES",
    title: "Electronie : Votre bureau d’études électroniques",
    link: "#",
  },
  {
    image: "/img/images/a4.jpeg",
    category: "ACTUALITÉS INDUSTRIES ÉLECTRONIQUES",
    title: "NVIDIA à theCamp Aix-en-Provence",
    link: "#",
  },
];

const NewsSection = () => {
   const [blogData, setBlogData] = useState({});

   useEffect(() => {
    setBlogData(blogJson);
 
}, []);

  return (
    <section className="news">
      <h2>Actualités</h2>
      <div className="news-grid">
        {blogData && blogData.posts && blogData.posts.map((news, index) => (
          <div className="news-card" key={index}>
            <div className="news-image" style={{ backgroundImage: `url(${news.background})` }}>
              <div className="news-category">{news.category}</div>
            </div>
            <div className="news-content">
              <h3>{news.title}</h3>
              <a href={news.link}>LIRE LA SUITE »</a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewsSection;
