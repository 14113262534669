import React from "react";
import Slider from "react-slick";

// Import the slick-carousel default styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./TestimonialSlider.css"; // Custom styles

// Custom Previous Arrow
const PrevArrow = ({ onClick }) => (
  <button className="slick-arrow slick-prev" onClick={onClick}>
    &#8249; {/* Unicode for left arrow (‹) */}
  </button>
);

// Custom Next Arrow
const NextArrow = ({ onClick }) => (
  <button className="slick-arrow slick-next" onClick={onClick}>
    &#8250; {/* Unicode for right arrow (›) */}
  </button>
);

function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <section className="testimonials-section">
      <h2 className="testimonials-title">Nos clients témoignent</h2>

      <Slider {...settings}>
        {/* Slide 1 */}
        <div className="testimonial-slide">
          <div className="testimonial-card">
            <div className="testimonial-avatar">
              <img src="/img/images/a1.jpg" alt="Warrick Renan" />
            </div>
            <div className="testimonial-content">
              <p className="testimonial-message">
                Electronie nous accompagne chez SOPHIA ENGINEERING depuis de nombreuses années
                et a toujours été un partenaire de confiance...
              </p>
              <h3 className="testimonial-name">Warrick Renan</h3>
              <p className="testimonial-position">Responsable BE Electronique</p>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="testimonial-slide">
          <div className="testimonial-card">
            <div className="testimonial-avatar">
              <img src="/img/images/a1.jpg" alt="Jean-Pierre Lintignac" />
            </div>
            <div className="testimonial-content">
              <p className="testimonial-message">
                Très réactif et coopératif, respect des délais annoncés et de la qualité...
              </p>
              <h3 className="testimonial-name">Jean-Pierre Lintignac</h3>
              <p className="testimonial-position">Directeur technique ATOMS</p>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
}

export default TestimonialSlider;
