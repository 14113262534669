import React from "react";
import "./GrayBlock.css";  

const GraySection = (props) => {
  const quality = props.props;

  const backgroundImageStyle = {
    backgroundImage: "url(" + quality.image + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "450px"
  };

  return (
    <section className="gray-block">
      <div className="background-image" style={backgroundImageStyle}></div>
      <div className="content-container">
        <div className="text-content">
          <h2>{quality.title}</h2>
          {quality.paragraph1 && <p>{quality.paragraph1}</p>}
          {quality.paragraph2 && <p>{quality.paragraph2}</p>}
          {quality.paragraph3 && <p>{quality.paragraph3}</p>}
        </div>
 
      </div>
    </section>
  );
};

export default GraySection;