import React, { useState, useEffect } from "react";
import BlogSection from "./Blog/BlogSection";
import NewsCards from "./Blog/NewsCards";                           
import blogJson from "../data/blog.json";

const Electronic = () => {
    const [blogData, setBlogData] = useState({});

    useEffect(() => {
        
        setBlogData(blogJson);
    }, []);

 
    return (
        <>
            {blogData.blog && <BlogSection blog={blogData.blog} />}
            {blogData.posts &&  <NewsCards posts={blogData.posts}/>}
        </>
    );
};
export default Electronic;