import React from "react";
// import "./Timeline.css"; // Import CSS file

const Timeline = () => {
  const timelineData = [
    {
      icon: "/images/domaines/fabrication.png",
      title: "Une Fabrication Conforme aux Normes les Plus Exigeantes",
      description: (
        <>
          Nous garantissons à nos clients des produits performants, fiables et conformes aux réglementations en vigueur grâce à :
          <br />✔️ Un processus de contrôle qualité rigoureux : inspection à chaque phase de production.
          <br />✔️ Des tests fonctionnels avancés : vérification des performances et des contraintes environnementales.
          <br />✔️ Une traçabilité complète : suivi des composants et des produits finis.
          <br />✔️ Un engagement zéro défaut : chaque produit est minutieusement contrôlé avant expédition.
          <br /><br />
          Nous appliquons les meilleures pratiques industrielles pour garantir la durabilité et la fiabilité de nos solutions électroniques et électrotechniques.
        </>
      ),
    },
    {
      icon: "/images/domaines/composant.png",
      title: "Certifications & Labels : Un Gage de Confiance",
      description: (
        <>
          Certification ISO 9001 : Management de la Qualité
          <br />Nous sommes certifiés ISO 9001, une norme internationale garantissant un système de gestion de la qualité efficace et une amélioration continue. Cette certification atteste de notre engagement à :
          <br /> ✔️ Fournir des produits et services conformes aux attentes de nos clients.
          <br /> ✔️ Améliorer continuellement nos processus pour accroître la satisfaction client.
          <br /> ✔️ Garantir une production maîtrisée et optimisée.
          <br /><br />
          Label UL Recognized (RU) : Sécurité et Fiabilité
          <br />✔️ Nos produits sont certifiés UL Recognized (RU), attestant de leur conformité aux normes de sécurité électrique et de performance.
          <br />✔️ Respect des normes internationales pour assurer la sécurité des équipements.
          <br />✔️  Fiabilité éprouvée pour garantir une intégration sans risque dans des systèmes complexes.
          <br />✔️ Tests approfondis pour assurer une compatibilité et une longévité optimales.
          <br /><br />
          Grâce à ces certifications, nous offrons à nos clients une garantie de qualité, de sécurité et de conformité aux standards internationaux.
        </>
      ),
    },
    {
      icon: "/images/domaines/quality1.webp",
      title: "Tests & Contrôles Qualité : Une Exigence Permanente",
      description: (
        <>
          Nous mettons en œuvre des protocoles de test avancés pour assurer la performance et la fiabilité de nos produits :
          <br />✔️ Contrôle des composants électroniques et électrotechniques dès leur réception.
          <br />✔️ Tests électriques et fonctionnels pour valider les performances des cartes électroniques.
          <br />✔️ Contrôles visuels et mécaniques sur les câblages et faisceaux électriques.
          <br />✔️ Simulations en conditions réelles pour garantir la robustesse et la longévité des systèmes.
          <br /><br />
          Chaque produit est soumis à des procédures de qualification strictes afin d'assurer une intégration sans faille et une fiabilité optimale.
        </>
      ),
    },
    {
      icon: "/images/domaines/carte1.png",
      title: " Un Engagement Qualité pour Nos Clients",
      description: (
        <>
          Faire appel à JCP Industries, c’est choisir un partenaire de confiance, qui place la qualité au cœur de son savoir-faire. Nous nous engageons à :
          <br />✔️ Respecter les normes les plus strictes du secteur électronique et électrotechnique.
          <br />✔️  Offrir des solutions fiables et sécurisées adaptées aux exigences de nos clients.
          <br />✔️  Garantir un suivi rigoureux et un accompagnement personnalisé tout au long du projet.
          <br /><br />
          Avec JCP Industries, exigez la qualité, la sécurité et la performance pour tous vos projets électroniques et électrotechniques.
          <br /><br />
        </>
      ),
    },
  ];

  return (
    <section className="timeline-section">
      <div className="timeline-container">
        {timelineData.map((item, index) => (
          <div className="timeline-item" key={index}>
            {/* Vertical Line */}
            <div className="timeline-line"></div>

            {/* Circle Icon */}
            <div className="timeline-icon">
              <img src={item.icon} alt="Timeline Icon" />
            </div>

            {/* Text Content */}
            <div className="timeline-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        <button className="cta-button">
          <a href="/contact" className="cta-link">📞 Contactez-nous dès aujourd’hui et concrétisons ensemble votre vision technologique !</a>
        </button>
      </div>
    </section>
  );
};

export default Timeline;